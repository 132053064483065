import { BrowserRouter, Routes, Route } from "react-router-dom";
import Protected from "./components/Protected";
import Login from "./pages/Login";
import Notice from "./pages/Notice";
import Dashboard from "./pages/Dashboard";
import NoticeHistory from "./pages/NoticeHistory";
import AddNotice from "./pages/AddNotice";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route
					path="/dashboard"
					element={
						<Protected>
							<Dashboard />
						</Protected>
					}
				/>
				<Route
					path="/notice"
					element={
						<Protected>
							<AddNotice />
						</Protected>
					}
				/>
				{/* <Route
					path="/notice"
					element={
						<Protected>
							<Notice />
						</Protected>
					}
				/> */}
				<Route
					path="/notice/:id"
					element={
						<Protected>
							<Notice />
						</Protected>
					}
				/>
				<Route
					path="/details/:id"
					element={
						<Protected>
							<NoticeHistory />
						</Protected>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}
