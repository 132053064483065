import React from "react";
import { Navigate } from "react-router-dom";
import Header from "./Header";

function Protected({ isSignedIn, children }) {
	if (isSignedIn) {
		return <Navigate to="/" replace />;
	}
	return (
		<>
			<div>
				<Header />
			</div>
			<div className="mt-20 px-10">{children}</div>
		</>
	);
}
export default Protected;
