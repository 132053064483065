import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
	name: 'auth',
	initialState: { user: null, token: '', factory_data: null, user_data: null },
	reducers: {
		setCredentials: (state, action) => {
			const { user, access_token, factory_data, user_data } = action.payload;
			state.user = user;
			state.token = access_token;
			state.factory_data = factory_data;
			state.user_data = user_data;
		},
		logOut: (state, action) => {
			state.user = null;
			state.token = null;
			state.factory_data = null;
			state.user_data = null;
		},
	},
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = state => state.auth.user;
export const selectCurrentToken = state => state.auth.token;
