import React from "react";

const NoticeHistory = () => {
	return (
		<div className="py-4">
			<div className="flex justify-between my-4 items-center">
				<p className="text-m font-bold mx-2">Name:- Himanshu Raj</p>
				<p className="text-m font-bold mx-2">Account No:- 40219117492</p>
				<p className="text-m font-bold mx-2">Loan Type: Education Loan</p>
				<p className="text-m font-bold mx-2">Loan Type: Education Loan</p>
				<div className="badge badge-primary p-4">Verified</div>
			</div>
			<p className="text-m text-red-500 font-bold mx-2">Delivery status</p>
			<div className="flex justify-between items-center">
				<p className="text-m font-bold mx-2">Name:- Himanshu Raj</p>
				<p className="text-m font-bold mx-2">Account No:- 40219117492</p>
				<div className="w-1/6 flex justify-between items-center">
					{/* <button
						type="submit"
						className="py-2 bg-brand-700 w-full rounded text-blue-50 font-bold hover:bg-brand-700 ml-4"
					>
						{" "}
						View Notice
					</button> */}
				</div>
			</div>
			<div className="flex justify-between items-center">
				<p className="text-m font-bold mx-2">Name:- Himanshu Raj</p>
				<p className="text-m font-bold mx-2">Account No:- 40219117492</p>
				<div className="w-1/6 flex justify-between my-4 items-center"></div>
			</div>
			<div className="divider"></div>

			<div className="flex justify-between items-center">
				<p className="text-m text-red-500 font-bold mx-2">Timeline</p>
				<div className="w-1/6 flex justify-between my-4 items-center"></div>
				<button className="btn btn-link">Track Consignment</button>
				<button className="btn btn-link">Download Reciept</button>
				<div className="w-1/6 flex justify-between items-center">
					<button
						type="submit"
						className="py-2 bg-brand-700 w-full rounded text-blue-50 font-bold hover:bg-brand-700 ml-4"
					>
						{" "}
						View Notice
					</button>
				</div>
			</div>
			<div className="flex justify-between items-center">
				<ul className="steps steps-vertical">
					<li className="step step-primary">
						<div className="flex flex-col items-start">
							<div>Notice Generated</div>
							<div>
								<span className="text-sm">21 Sept 2023</span>
							</div>
						</div>
					</li>
					<li className="step step-primary">
						<div className="flex flex-col items-start">
							<div>Verification Pending</div>
							<div>
								<span className="text-sm">21 Sept 2023</span>
							</div>
						</div>
					</li>
					<li className="step step-primary">
						<div className="flex flex-col items-start">
							<div>Whatsapp Notice Delivered</div>
							<div>
								<span className="text-sm">21 Sept 2023</span>
							</div>
						</div>
					</li>
					<li className="step step-primary">
						<div className="flex flex-col items-start">
							<div>Email Notice Delivered</div>
							<div>
								<span className="text-sm">21 Sept 2023</span>
							</div>
						</div>
					</li>
					<li className="step step-primary">
						<div className="flex flex-col items-start">
							<div>SMS Notice Delivered</div>
							<div>
								<span className="text-sm">21 Sept 2023</span>
							</div>
						</div>
					</li>
					<li className="step step-primary">
						<div className="flex flex-col items-start">
							<div>Consignment Booked</div>
							<div>
								<span className="text-sm">21 Sept 2023</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default NoticeHistory;
