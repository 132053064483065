import React from "react";
import LoginImg from "../assets/images/login.png";
import PNB from "../assets/images/pnb.png";
import AXIS from "../assets/images/AXISBank_Logo.png";

const Login = () => {
	return (
		<div>
			<div className="flex justify-center items-center w-screen h-screen">
				<div className="w-1/2 flex justify-center items-center flex-col">
					<div className="flex justify-center container mx-auto mt-6 text-slate-500 text-sm">
						<div className="flex flex-col sm:flex-row justify-between items-center">
							<img src={LoginImg} alt="login" />
						</div>
					</div>
				</div>
				<div className="w-1/2 flex justify-center items-center flex-col h-screen bg-slate-100">
					<div className="text-slate-500 w-1/2 items-center">
						<img src={AXIS} alt="login" />
					</div>
					<div className="w-full flex flex-col items-center pt-12">
						<div className="w-3/5 mb-6">
							<input
								type="email"
								name="email"
								id="email"
								className="w-full py-4 px-8 bg-white placeholder:font-semibold rounded hover:ring-1 hover:ring-brand-100 outline-brand-200 border-solid border-1 border-slate-100"
								placeholder="Email adress"
							/>
						</div>
						<div className="w-3/5 mb-6">
							<input
								type="password"
								name="password"
								id="password"
								className="w-full py-4 px-8 bg-white placeholder:font-semibold rounded hover:ring-1 hover:ring-brand-100 outline-brand-200  border-solid border-1 border-slate-100"
								placeholder="Password"
							/>
						</div>
						<div className="w-3/5 mb-12">
							<button
								type="submit"
								className="py-4 bg-brand-700 w-full rounded text-blue-50 font-bold hover:bg-brand-700"
							>
								{" "}
								LOGIN
							</button>
						</div>
					</div>
					<div className="flex w-3/5 justify-between mt-6 text-slate-500 text-sm">
						<div>Forgot your password</div>
						<div>Don't have an account? Get Started</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
