import React from "react";
import ButtonOutline from "./ButtonOutline";
import PNB from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
	let navigate = useNavigate();

	const goToHome = () => {
		navigate("/dashboard");
	};

	const logoutHandler = () => {
		navigate("/");
	};

	return (
		<div>
			<header
				className={"fixed top-0 w-full z-30 bg-white transition-all shadow-md"}
			>
				<nav className="px-10 mx-auto grid grid-flow-col py-4 sm:py-4">
					<div className="col-start-1 col-end-2 flex items-center">
						<img
							src={PNB}
							alt="login"
							className="w-[175px]"
							onClick={goToHome}
						/>
						<div className="ml-4">
							<div className="font-bold">Hari Complex, Sasaram</div>
							<div className="text-sm">IFSC: PUNB084600, Branch Code: 085</div>
						</div>
					</div>

					<div className="col-start-10 col-end-12 font-medium flex justify-end items-center">
						<ButtonOutline onClick={logoutHandler}>Logout</ButtonOutline>
					</div>
				</nav>
			</header>
		</div>
	);
};

export default Header;
