import React from "react";

const ButtonOutline = ({ children }) => {
	return (
		<button className="font-medium tracking-wide py-2 px-5 sm:px-8 border border-blue-600 text-blue-600 bg-white-500 outline-none rounded-l-full rounded-r-full capitalize hover:bg-blue-600 hover:text-white transition-all hover:shadow-blue">
			{" "}
			{children}
		</button>
	);
};

export default ButtonOutline;
