import React from "react";

const Notice = () => {
	const openModalHandler = () => {
		document.getElementById("my_modal_3").showModal();
	};

	return (
		<div className="py-4">
			<div className="flex justify-between my-4 items-center">
				<p className="text-m font-bold mx-2">Name:- Himanshu Raj</p>
				<p className="text-m font-bold mx-2">Account No:- 40219117492</p>
				<p className="text-m font-bold mx-2">Loan Type: Education Loan</p>
				<div className="w-1/6">
					<div className="badge badge-primary p-4">Verification Pending</div>
				</div>
				<div className="w-1/6">
					<button
						type="submit"
						className="py-2 bg-brand-700 w-full rounded text-blue-50 font-bold hover:bg-brand-700"
					>
						{" "}
						Download Notice
					</button>
				</div>
			</div>

			<div className="card w-3/4 bg-base-100 shadow-xl m-auto mt-4">
				<div className="card-body">
					<h2 className="card-title">Shoes!</h2>
					<p>If a dog chews shoes whose shoes does he choose?</p>
				</div>
			</div>

			<div className="fixed w-full left-0 px-10 bottom-0">
				<div className="divider"></div>
				<div className="flex justify-between my-4 items-center">
					<div className="w-1/6"></div>
					<div className="w-1/6"></div>
					<div className="w-1/6"></div>
					<div className="w-1/6">
						<button
							type="submit"
							className="py-2 bg-warning w-full rounded text-blue-50 font-bold hover:bg-warning"
						>
							{" "}
							Edit
						</button>
					</div>
					<div className="w-1/6">
						<button
							type="submit"
							className="py-2 bg-success w-full rounded text-blue-50 font-bold hover:bg-success"
						>
							{" "}
							Approve
						</button>
					</div>
					<div className="w-1/6">
						<button
							type="submit"
							className="py-2 bg-brand-700 w-full rounded text-blue-50 font-bold hover:bg-brand-700"
							onClick={openModalHandler}
						>
							{" "}
							Next
						</button>
					</div>
				</div>
			</div>
			<dialog id="my_modal_3" className="modal">
				<div className="modal-box">
					<form method="dialog">
						{/* if there is a button in form, it will close the modal */}
						<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
							✕
						</button>
					</form>
					<h3 className="font-bold text-lg">Account No.: 13141343414143</h3>
					<p className="py-4">Send Email and SMS Notice</p>
					<div className="flex justify-end">
						<button className="btn btn-primary">Send</button>
					</div>
				</div>
			</dialog>
		</div>
	);
};

export default Notice;
