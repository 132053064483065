import React from "react";
import Overview from "./components/Overview";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
	let navigate = useNavigate();

	const noticeHandler = (type) => {
		navigate("/notice/1");
	};

	const addNoticeHandler = (type) => {
		navigate("/notice");
	};

	return (
		<div>
			<Overview />
			<div className="divider"></div>
			<div className="flex justify-between my-4">
				<input
					type="email"
					name="email"
					id="email"
					className="w-1/4 py-2 px-4 bg-slate-100 placeholder:font-semibold rounded hover:ring-1 hover:ring-brand-100 outline-slate-100 border-solid border-1 border-slate-100"
					placeholder="Search account number"
				/>
				<div className="w-1/6">
					<button
						type="submit"
						className="py-2 bg-brand-700 w-full rounded text-blue-50 font-bold hover:bg-brand-700"
						onClick={addNoticeHandler}
					>
						{" "}
						Generate new notice
					</button>
				</div>
			</div>
			<div className="overflow-x-auto">
				<table className="table">
					{/* head */}
					<thead>
						<tr>
							<th>Account No</th>
							<th>Name</th>
							<th>Loan Type</th>
							<th>Delivery Status</th>
						</tr>
					</thead>
					<tbody>
						{/* row 1 */}
						<tr>
							<th>1</th>
							<td>Cy Ganderton</td>
							<td>Quality Control Specialist</td>
							<td>Blue</td>
							<td>
								<button
									className="btn btn-ghost btn-xs"
									onClick={noticeHandler}
								>
									details
								</button>
							</td>
						</tr>
						{/* row 2 */}
						<tr>
							<th>2</th>
							<td>Hart Hagerty</td>
							<td>Desktop Support Technician</td>
							<td>Purple</td>
							<td>
								<button
									className="btn btn-ghost btn-xs"
									onClick={noticeHandler}
								>
									details
								</button>
							</td>
						</tr>
						{/* row 3 */}
						<tr>
							<th>3</th>
							<td>Brice Swyre</td>
							<td>Tax Accountant</td>
							<td>Red</td>
							<td>
								<button
									className="btn btn-ghost btn-xs"
									onClick={noticeHandler}
								>
									details
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Dashboard;
