import React from "react";

const Overview = () => {
	return (
		<div className="py-8">
			<p className="text-lg font-bold mx-2 mb-2">Overview</p>
			<div className="flex justify-between">
				<div className="w-1/5 overflow-hidden rounded-lg shadow-lg px-4 py-4 mx-2 bg-slate-100">
					<p className="text-sm">Total Notice</p>
					<p className="text-grey-darker text-lg font-bold">1000</p>
				</div>
				<div className="w-1/5 overflow-hidden rounded-lg shadow-lg px-4 py-4 mx-2 bg-red-50">
					<p className="text-sm">Total Delivered</p>
					<p className="text-grey-darker text-lg font-bold">1000</p>
				</div>
				<div className="w-1/5 overflow-hidden rounded-lg shadow-lg px-4 py-4 mx-2 bg-blue-50">
					<p className="text-sm">Verification Pending</p>
					<p className="text-grey-darker text-lg font-bold">100</p>
				</div>
				<div className="w-1/5 overflow-hidden rounded-lg shadow-lg px-4 py-4 mx-2 bg-green-50">
					<p className="text-sm">Total Out For Delivery</p>
					<p className="text-grey-darker text-lg font-bold">1134</p>
				</div>
				<div className="w-1/5 overflow-hidden rounded-lg shadow-lg px-4 py-4 mx-2 bg-yellow-50">
					<p className="text-sm">Total Pending</p>
					<p className="text-grey-darker text-lg font-bold">119</p>
				</div>
			</div>
		</div>
	);
};

export default Overview;
